import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export interface Area {
    label: string;
    value: number;
}

interface AreaState {
    areas: Area[],
    loader: boolean
}


const initialState: AreaState = {
    areas: [],
    loader: true
}

export const fetchArea = createAsyncThunk(
    'area/fetch',
    async (thunkAPI, { getState }) => {
        const state = getState() as { token: { token: string } };

        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BASE_URL + '/v1/areas',
            headers: {
                'Authorization': `Bearer ${state.token.token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 401) {
            sessionStorage.removeItem('token')
            window.location.reload()
        }

        let data = response.data.data;
        let loader = false;

        return { loader, data };
    }
);

export const AreaSlice = createSlice({
    name: 'area',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchArea.fulfilled, (state, action) => {
            state.areas = action.payload.data;
            state.loader = action.payload.loader;
        });
    }
});

export default AreaSlice.reducer;