import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
import axios from "axios";
import qs from 'qs';



interface TokenState {
    token: string
}

const initialState: TokenState = {
    token: ''
}

export const generateToken = createAsyncThunk(
    'token/generate',
    async (thunkAPI) => {
        if (sessionStorage.getItem('token')) {
            const token = sessionStorage.getItem('token') || '';

            return CryptoJS.AES.decrypt(token, process.env.REACT_APP_CLIENT_ID || '').toString(CryptoJS.enc.Utf8);
        }

        const response = await axios({
            method: 'POST',
            url: process.env.REACT_APP_AUTH_URL,
            auth: {
                username: process.env.REACT_APP_CLIENT_ID || '',
                password: process.env.REACT_APP_CLIENT_SECRET || ''
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: qs.stringify({
                grant_type: 'client_credentials',
                scope: 'eterna-jardin/website'
            })
        });

        const data = response.data;
        const token = data.access_token;
        const encrypted_token = CryptoJS.AES.encrypt(token, process.env.REACT_APP_CLIENT_ID || '').toString()

        sessionStorage.setItem('token', encrypted_token);

        return token;
    }
);

export const TokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(generateToken.fulfilled, (state, action) => {
            state.token = action.payload;
        });
    }
});

export default TokenSlice.reducer;