import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface LoaderState {
    loader: boolean
}

const initialState: LoaderState = {
    loader: false
}

export const LoaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        setLoader: (state, action: PayloadAction<boolean>) => {
            state.loader = action.payload;
        }
    }
});

export default LoaderSlice.reducer;
export const { setLoader } = LoaderSlice.actions;