import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export interface Classification {
    label: string;
    value: number;
}

interface ClassificationState {
    classifications: Classification[],
    loader: boolean
}

const initialState: ClassificationState = {
    classifications: [],
    loader: true
}

export const fetchClassification = createAsyncThunk(
    'classification/fetch',
    async (thunkAPI, { getState }) => {
        const state = getState() as { token: { token: string } };

        const response = await axios({
            method: 'GET',
            url: process.env.REACT_APP_API_BASE_URL + '/v1/classifications',
            headers: {
                'Authorization': `Bearer ${state.token.token}`,
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 401) {
            sessionStorage.removeItem('token')
            window.location.reload()
        }

        let data = response.data.data;
        let loader = false;

        return { loader, data };
    }
);

export const ClassificationSlice = createSlice({
    name: 'classification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClassification.fulfilled, (state, action) => {
            state.classifications = action.payload.data;
            state.loader = action.payload.loader;
        });
    }
});

export default ClassificationSlice.reducer;