import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { TokenSlice } from "./Features/tokenSlice";
import { TypeSlice } from "./Features/Map/typeSlice";
import { ClassificationSlice } from "./Features/Map/classificationSlice";
import { AreaSlice } from "./Features/Map/areaSlice";
import { SectionSlice } from "./Features/Map/sectionSlice";
import { MapCollectionSlice } from "./Features/Map/mapCollectionSlice";
import { LoaderSlice } from "./Features/loaderSlice";

export const store = configureStore({
    reducer: {
        token: TokenSlice.reducer,
        loader: LoaderSlice.reducer,
        type: TypeSlice.reducer,
        area: AreaSlice.reducer,
        classification: ClassificationSlice.reducer,
        section: SectionSlice.reducer,
        map: MapCollectionSlice.reducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;