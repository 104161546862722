import React, { lazy, useEffect } from 'react';
import Lazy from './Components/Lazy';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MessengerChat } from 'react-messenger-chat-plugin';
import { useAppDispatch, useAppSelector } from './Store/store';
import { generateToken } from './Store/Features/tokenSlice';
import Loader from './Components/Loader';

const Home = Lazy(() => import('./Pages/Home'));
const Lots = Lazy(() => import('./Pages/Lots'));
const Chapels = Lazy(() => import('./Pages/Chapels'));
const Services = Lazy(() => import('./Pages/Services'));
const Terms = Lazy(() => import('./Pages/Terms'));
const Privacy = Lazy(() => import('./Pages/Privacy'));
const NotFound = Lazy(() => import('./Pages/NotFound'));

function appendScript(filePath: string) {
  const script = document.createElement('script');
  script.src = filePath;
  document.body.appendChild(script);
}

const scripts = [
  '/assets/js/popper.min.js',
  '/assets/js/jarallax.js', '/assets/js/jquery.magnific-popup.min.js', '/assets/js/jquery.meanmenu.js',
  '/assets/js/scrollreveal.min.js', '/assets/js/jquery-ui.js',
  '/assets/js/gmap3.min.js', '/assets/js/switch.js', '/assets/js/script.js'
];

for (let i = 0; i < scripts.length; i++) {
  appendScript(scripts[i]);
}

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(generateToken());
  });

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path='/lots/:lot' element={<Lots />} />
          <Route path='/chapels/:chapel' element={<Chapels />} />
          <Route path='/services/:service' element={<Services />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/' element={<Home />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <MessengerChat
        pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID || ''}
        themeColor={"#259e4d"}
        loggedInGreeting="Hi! How can we help you?"
        loggedOutGreeting="Hi! How can we help you?"
        greetingDialogDisplay={"show"}
        debugMode={true}

      />
    </React.Fragment>
  );
}

// class App extends React.Component {
//   appendScript(filePath: string) {
//     const script = document.createElement('script');
//     script.src = filePath;
//     document.body.appendChild(script);
//   }

//   componentDidMount(): void {
//     const scripts = [
//       '/assets/js/bootstrap.min.js', '/assets/js/popper.min.js',
//       '/assets/js/jarallax.js', '/assets/js/jquery.magnific-popup.min.js', '/assets/js/jquery.meanmenu.js',
//       '/assets/js/scrollreveal.min.js', '/assets/js/jquery-ui.js',
//       '/assets/js/gmap3.min.js', '/assets/js/switch.js', '/assets/js/script.js'
//     ];

//     // const scripts = [
//     //   'assets/js/bootstrap.min.js', 'assets/js/popper.min.js', 'assets/js/owl.carousel.min.js',
//     //   'assets/js/jarallax.js', 'assets/js/jquery.magnific-popup.min.js', 'assets/js/lightbox.js', 'assets/js/jquery.meanmenu.js',
//     //   'assets/js/scrollreveal.min.js', 'assets/js/jquery.counterup.min.js', 'assets/js/waypoints.min.js', 'assets/js/jquery-ui.js',
//     //   'assets/js/gmap3.min.js', 'assets/js/switch.js', 'assets/js/script.js'
//     // ];

//     for (let i = 0; i < scripts.length; i++) {
//       this.appendScript(scripts[i]);
//     }
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <BrowserRouter>
//           <Routes>
//             <Route path='/lots/:lot' element={<Lots />} />
//             <Route path='/chapels/:chapel' element={<Chapels />} />
//             <Route path='/terms' element={<Terms />} />
//             <Route path='/privacy' element={<Privacy />} />
//             <Route path='/' element={<Home />} />
//             <Route path='*' element={<NotFound />} />
//           </Routes>
//         </BrowserRouter>
//         <MessengerChat
//           pageId="670341679647425"
//           themeColor={"#259e4d"}
//           loggedInGreeting="Hi! How can we help you?"
//           loggedOutGreeting="Hi! How can we help you?"
//           greetingDialogDisplay={"show"}
//           debugMode={true}

//         />
//       </React.Fragment>
//     );
//   }
// }

export default App;