import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export interface Lot {
    id: number;
    number: string;
    sqm: number;
    no_of_lots: number;
    bones: number;
    fresh_bodies: number;
    status: string;
    marker: string;
    type: string;
    section: string;
    classification: string;
    area: string;
    owner: string;
}

interface FilterEvent {
    label: string;
    value: number;
}

interface Params {
    type_id?: number;
    area_id?: number;
    classification_id?: number;
    section_id?: number
}

interface MapCollection {
    lots: Lot[];
    loader: boolean;
    isModalLotInfoShow: boolean,
    isModalLotInquiryShow: boolean,
    lot: Lot | null;
    typeId: number;
    areaId: number;
    classificationId: number;
    sectionId: number;
}

const initialState: MapCollection = {
    lots: [],
    loader: true,
    isModalLotInfoShow: false,
    isModalLotInquiryShow: false,
    lot: null,
    typeId: 0,
    areaId: 0,
    classificationId: 0,
    sectionId: 0
}

export const fetchLot = createAsyncThunk(
    'lot/fetch',
    async (thunkAPI, { getState }) => {
        const state = getState() as { token: { token: string }, map: MapCollection };
        let params: Params = {};

        if (state.map.typeId > 0) {
            params.type_id = state.map.typeId;
        }

        if (state.map.areaId > 0) {
            params.area_id = state.map.areaId;
        }

        if (state.map.classificationId > 0) {
            params.classification_id = state.map.classificationId;
        }

        if (state.map.sectionId > 0) {
            params.section_id = state.map.sectionId;
        }

        try {
            const response = await axios({
                method: 'GET',
                url: process.env.REACT_APP_API_BASE_URL + '/v1/lots',
                params,
                headers: {
                    'Authorization': `Bearer ${state.token.token}`,
                    'Content-Type': 'application/json'
                }
            });

            let data = response.data.data;
            let loader = false;

            return { data, loader };
        } catch (e) {
            if (axios.isAxiosError(e) && e.response) {
                if (e.response.status === 401 && sessionStorage.getItem('token')) {
                    sessionStorage.removeItem('token');
                    window.location.reload();
                }
            }

            return { loader: true, data: [] };
        }
    }
);


export const MapCollectionSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<{ name: string, event: FilterEvent | null }>) => {
            if (action.payload.event) {
                switch (action.payload.name) {
                    case 'type':
                        state.typeId = action.payload.event.value;
                        break;

                    case 'area':
                        state.areaId = action.payload.event.value;
                        break;

                    case 'classification':
                        state.classificationId = action.payload.event.value;
                        break;

                    case 'section':
                        state.sectionId = action.payload.event.value;
                        break;
                }

                state.loader = true;

            }
        },
        setLot: (state, action: PayloadAction<Lot | null>) => {
            state.lot = action.payload;
        },
        setModalLotInfoShow: (state, action: PayloadAction<boolean>) => {
            state.isModalLotInfoShow = action.payload;
        },
        setModalLotInquiryShow: (state, action: PayloadAction<boolean>) => {
            state.isModalLotInquiryShow = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLot.fulfilled, (state, action) => {
            state.lots = action.payload.data;
            state.loader = action.payload.loader;
        });
    }
});

export default MapCollectionSlice.reducer;
export const { setFilter, setLot, setModalLotInfoShow, setModalLotInquiryShow } = MapCollectionSlice.actions;