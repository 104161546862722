import React from "react";
import './index.css';

interface Props {
    show: boolean;
    relative?: boolean;
}

interface State {

}

class Loader extends React.Component<Props, State> {
    render(): React.ReactNode {
        return this.props.show ? (
            <React.Fragment>
                <div className={this.props.relative ? 'loader-area-relative' : 'loader-area'}>
                    <center>
                        <div className="loader"></div>
                        <img src="/assets/img/logo/logo.png" alt="logo" />
                    </center>
                </div>
            </React.Fragment>
        ) : (
            <React.Fragment></React.Fragment>
        );
    }
}

export default Loader;