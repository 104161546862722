import React from 'react';
import './index.css';
import Loader from '../Loader';

interface Props {

}

interface State {
    component: string | null
}

const Lazy = (importComp: any) => {
    return class extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);

            this.state = {
                component: null
            };
        }

        componentDidMount() {
            importComp().then((comp: any) => this.setState({ component: comp.default }));
        }

        render(): React.ReactNode {
            const Component = this.state.component;

            return Component ? (
                <Component {...this.props} />
            ) : (
                <Loader show={true} />
            );
        }
    };
};

export default Lazy;